<template>
  <div>
    <ProjectBar />
    <AdminList />
    <div id="fadeLayer"></div>
    <div class="loading">
      <vue-loading
        id="loading"
        type="spin"
        color="#333"
        :size="{ width: '50px', height: '50px' }"
      ></vue-loading>
    </div>
  </div>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
import AdminList from "@/components/AdminListHome/AdminListHomeComponent.vue";
import ProjectBar from "@/components/ProjectBar/ProjectBarComponent.vue";
import { VueLoading } from "vue-loading-template";
import AuthStateCheckMixin from "../utility/authStateCheck";
export default {
  name: "AdminListHome",
  components: {
    AdminList,
    ProjectBar,
    VueLoading,
  },
  mounted() {
    //認証情報があるか確認
    this.authStateCheck(this.$store, this.$router);

    //ローディング表示
    this.waitLoading();

    //管理者をfirebaseストレージから取得する
    this.getAdmins();
    //this.getCLientAdmin();
  },
  data: function () {
    return {
      temporaryData: [],
    };
  },
  mixins: [AuthStateCheckMixin],
  methods: {
    getAdmins: async function () {
      //管理者情報をリセット
      this.$store.commit("resetAdminInfo");
      var instance = this;

      //クライアントIDを取得
      var clientId = this.$store.state.clientId;
      var store = this.$store;

      //取得先パス
      var listRef = firebase
        .storage()
        .ref()
        .child("/account/" + clientId + "/");
      var list = await listRef.listAll();
      var imgList = [];
      Promise.all(
        list.items.map(async (element) => {
          await imgList.push(element);
        })
      );
      for (var i = 0; i < imgList.length; i++) {
        imgList[i].getDownloadURL().then(async (url) => {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url);
          xhr.onload = async function (event) {
            var blob = xhr.response;
            var managerList = JSON.parse(blob);
            console.log(managerList);

            //マスターアカウントを取得
            var masterAdmin = await instance.getCLientAdmin();

            //配列の先頭に入れる
            managerList.unshift(masterAdmin);
            store.commit("setAdminInfo", managerList);

            //ローディングを解除
            instance.downloaded();
          };
          xhr.send();
        });
      }
    },
    /////////////////////////////////////////
    ////////////////////////////////
    /////////////////////
    /////////説明
    ///マスター管理者を取得する
    ///
    ///
    ////////////////
    ///////////////////////
    /////////////////////////////////
    ///////////////////////////////////////////////////////////
    getCLientAdmin: async function () {
      //クライアントを取得する
      var masterAdmin = this.$store.state.clientInfo[0];

      //マスターのメールアドレスを取得する
      var masterMailAddress = masterAdmin.mail;

      //マスターのパスワードを取得する
      var masterPass = masterAdmin.password;

      //マスターの名前を取得する
      var masterName = masterAdmin.maneger;

      //権限を取得する
      var authority = 1;

      var masterAccountInfo = {
        mail_address: masterMailAddress,
        pass: masterPass,
        admin_name: masterName,
        authority: authority,
      };

      return masterAccountInfo;
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得している間の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得した後の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
  },
};
</script>
<style scoped>
#fadeLayer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  z-index: 999999999;
}

#loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 80%;
  height: 3.2rem;
}
</style>