<template>
  <div class="container">
    <div class="row">
      <div class="subject col-12">管理者新規作成</div>
    </div>
    <div class="row admin-name">
      <div class="col-2 admin-name-topic">管理者名</div>
      <div class="col-1">
        <input
          style="width: 403px"
          type="text"
          id="admin_name"
          name="admin_name"
          v-model="admin_name"
        />
      </div>
    </div>
    <p id="admin-name-alert">※管理者名を入力してください</p>
    <div class="row admin-id">
      <div class="col-2 admin-id-topic">メールアドレス</div>
      <div class="col-1">
        <input
          style="width: 403px"
          type="text"
          id="admin_address"
          v-model="admin_address"
        />
      </div>
    </div>
    <p id="admin-id-alert">※メールアドレスを入力してください</p>
    <p id="auth-mail-alert">※すでに登録されているメールアドレスです</p>
    <p id="invalid-mail-alert">※正しいメールアドレスを入力してください</p>
    <div class="row">
      <div class="col-2"></div>
      <div class="reserve-button-div">
        <button class="reserve-button" type="submit" v-on:click="reserve()">
          保存
        </button>
      </div>
      <div class="cancel-button-div">
        <button class="cancel-button" type="submit" v-on:click="cancel()">
          キャンセル
        </button>
      </div>
    </div>
  </div>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-auth.js"></script>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-functions.js"></script>
<script type="module">
export default {
  name: "AdminCreate",
  mounted() {},
  data: function () {
    return {
      admin_name: "",
      admin_address: "",
      admin_pass: "",
    };
  },
  methods: {
    /////////////////////////////////////////
    ////////////////////////////
    /////説明
    ////管理者の保存処理
    ////
    ////引数：なし
    /////////////////////////
    //////////////////////////////////////////////////
    reserve: function () {
      if (
        //フォームが全て記入済みなら
        this.admin_name != "" &&
        this.admin_address != ""
      ) {
        //ローディング表示
        this.waitLoading();

        //アラートを非表示にする
        this.noDisplayAlert();

        //パスワードを自動生成
        this.createPassword();

        //管理者をauthentication上に登録する
        this.createdAdmin();
      } else {
        //一つでも未記入があれば
        if (this.admin_name == "") {
          document.getElementById("admin-name-alert").style.display = "block";
        } else {
          document.getElementById("admin-name-alert").style.display = "none";
        }
        if (this.admin_address == "") {
          document.getElementById("admin-id-alert").style.display = "block";
        } else {
          document.getElementById("admin-id-alert").style.display = "none";
        }
      }
    },
    //////////////////////////////////////////////
    ///////
    ///////説明：アラートを非表示にする関数
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    noDisplayAlert: function () {
      //アラート非表示
      document.getElementById("admin-name-alert").style.display = "none";
      document.getElementById("admin-id-alert").style.display = "none";
      document.getElementById("auth-mail-alert").style.display = "none";
      document.getElementById("invalid-mail-alert").style.display = "none";
    },
    //////////////////////////////////////////////
    ///////
    ///////説明：管理者をauthentication上に登録する
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    createdAdmin: async function () {
      var router = this.$router;
      var instance = this;
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.admin_address, this.admin_pass)
        .then(async (user) => {
          console.log("アカウントを作成しました");

          //カスタムクレームを登録するユーザーのuidを取得し、functionsに渡す
          var uid = user.user.uid;

          //管理者が属するクライアントIDを取得する
          var clientId = this.$store.state.clientId;

          //サインアウトしないとfirebase storageのセキュリティルールにひっかかる
          firebase
            .auth()
            .signOut()
            .then(async () => {
              await firebase
                .auth()
                .signInWithEmailAndPassword(
                  this.$store.state.loginEmail,
                  this.$store.state.loginPassword
                )
                .then(async () => {
                  console.log("ログインしなおしました");
                  //storageに保存する
                  await instance.reserveAdmin(user.user.uid);

                  //カスタムクレームを登録する
                  await instance.registerCustomClaim(uid, clientId);

                  //ローディング非表示
                  instance.downloaded();

                  router.push("managimentList");
                });
            });
        })
        .catch((error) => {
          console.log(error.message);
          if (error.code == "auth/invalid-email") {
            document.getElementById("invalid-mail-alert").style.display =
              "block";
          } else if (error.code == "auth/email-already-in-use") {
            document.getElementById("auth-mail-alert").style.display = "block";
          }
          //ローディング非表示
          instance.downloaded();
        });
    },
    //////////////////////////////////////////////
    ///////
    ///////説明：authenticationに登録したら自動でそのアカウントにログインしてしまうので、ログアウトして再度ログインする
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    async reSignInFirebase(uid) {
      //サインアウトしないとfirebase storageのセキュリティルールにひっかかる
      firebase.auth().signOut();
      await firebase
        .auth()
        .signInWithEmailAndPassword(
          this.$store.state.loginEmail,
          this.$store.state.loginPassword
        );
    },
    //////////////////////////////////////////////
    ///////
    ///////説明：管理者をfirebaseStorageに登録する
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    reserveAdmin: async function (uidNum) {
      //管理者すべてを取得する
      var admins = await this.deleteMasterAdmin();

      //保存先パス
      const ref = firebase
        .storage()
        .ref()
        .child("/account/" + this.$store.state.clientId + "/managerList.json");

      //保存する管理者情報
      const addAdminInfo = {
        mail_address: this.admin_address,
        pass: this.admin_pass,
        admin_name: this.admin_name,
        uid: uidNum,
        authority: "0",
      };
      admins.push(addAdminInfo);

      //jsonフォーマットに変換
      var jsonFormat = JSON.stringify(admins);

      const blob = new Blob([jsonFormat], { type: "application/json" });
      ref.put(blob);
    },
    //////////////////////////////////////////////
    ///////
    ///////説明：マスターアカウントは他の管理者と保存場所が異なるので、createするときは一旦削除する
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    deleteMasterAdmin: async function () {
      //すべての管理者を取得する。この中から削除するユーザーを消す
      var managerList = this.$store.state.admins;

      //一度にユーザーを削除する数
      var deleteQuantity = 1;

      //マスター管理者のインデックス番号
      var masterAccountIndex = 0;

      //マスター管理者をjsonから削除する。マスター管理者はこのjsonでは管理しないため
      managerList[0].splice(masterAccountIndex, deleteQuantity);

      return managerList[0];
    },
    //////////////////////////////////////////////
    ///////
    ///////説明：パスワードを自動生成する
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    createPassword: function () {
      //英数字を用意する
      var letters = "abcdefghijklmnopqrstuvwxyz";
      var numbers = "0123456789";
      var string = letters + letters.toUpperCase() + numbers;
      //toUpperCase()  小文字を大文字に変換

      var len = 8; //8文字
      this.admin_pass = ""; //文字列が空っぽという定義をする
      for (var i = 0; i < len; i++) {
        this.admin_pass += string.charAt(
          Math.floor(Math.random() * string.length)
        );
      }
    },
    /////////////////////////////////////////
    ////////////////////////////
    /////説明
    ////キャンセルが押された場合、プロジェクト一覧画面に戻る
    ////
    ////引数：なし
    /////////////////////////
    //////////////////////////////////////////////////
    cancel: function () {
      this.$router.push("managimentList");
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得している間の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得した後の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
    //////////////////////////////////////////////
    ////////説明：ユーザーのカスタムクレームにクライアントIDを登録する
    /////
    /////
    /////
    /////引数:なし
    /////////////////////////////////////////////////////////
    registerCustomClaim: async function (uid, clientID) {
      //関数に送るデータ
      var clientIdList = {
        groups: { [clientID]: true },
        uid: { uid: uid },
      };
      var addMessage = firebase.functions().httpsCallable("helloWorld");
      addMessage(clientIdList);
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap");

.admin-name-topic {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.admin-id-topic {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

#admin_name {
  border: 2px solid rgb(170, 165, 165); /* 枠線 */
  background-color: rgb(255, 255, 255); /* 背景色 */
}

#admin_address {
  border: 2px solid rgb(170, 165, 165); /* 枠線 */
  background-color: rgb(255, 255, 255); /* 背景色 */
}

.container {
  margin-top: 5%;
}

.subject {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 32px;
  margin-left: 0%;
}

.admin-name {
  margin-top: 3%;
}

.admin-id {
  margin-top: 24px;
}

.reserve-button-div {
  margin-top: 80px;
  margin-left: 13px;
}

.cancel-button-div {
  margin-top: 80px;
  margin-left: 16px;
}

.reserve-button {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  outline: 0;
  background: transparent linear-gradient(180deg, #00979c 0%, #068587 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  width: 100px;
  height: 40px;
  border: 0;
  color: #ffffff;
}

.cancel-button {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  outline: 0;
  background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  width: 100px;
  height: 40px;
  border: 0;
  color: #ffffff;
}

.used-content {
  position: relative;
  background: linear-gradient(#ff8c00, 25%, #ff5f17);
  height: 20px;
  top: 12px;
}

p {
  position: relative;
  top: -12px;
}
#admin-name-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

#admin-id-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

#auth-mail-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}
#invalid-mail-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}
</style>