<template>
  <div class="container">
    <div class="row">
      <div class="subject-project-register col-12">プロジェクト新規作成</div>
    </div>
    <div class="row client_name">
      <div class="col-2 project-name">プロジェクト名</div>
      <div class="col-1">
        <input
          style="width: 403px"
          type="text"
          id="project_name"
          name="project_name"
          v-model="project_name"
        />
      </div>
    </div>
    <p id="project-name-alert">※プロジェクト名を入力してください</p>
    <div class="row event_priod">
      <div class="col-2 project-period">プロジェクト期間</div>
      <div class="col-1 contract_start_day">
        <input
          style="width: 170px"
          type="date"
          id="contract_start_day"
          name="contract_start_day"
          v-model="contract_start_day"
        />
      </div>
      <div class="col-1 wavy_line"><span>〜</span></div>
      <div class="col-4 contract_finished_day">
        <input
          style="width: 170px"
          type="date"
          id="contract_finished_day"
          name="contract_finished_day"
          v-model="contract_finished_day"
        />
      </div>
    </div>
    <p id="contract-period-alert">※期間を選択してください</p>
    <div class="row client-web-url">
      <div class="col-2 site-url">掲載サイトURL</div>
      <div class="col-1">
        <input
          style="width: 403px"
          type="text"
          id="site-url"
          name="url"
          v-model="url"
        />
      </div>
    </div>
    <p id="url-alert">※URLを記入してください</p>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-1">
        <button class="reserve-button" type="submit" v-on:click="reserve()">
          保存
        </button>
      </div>
      <div class="col-1">
        <button class="cancel-button" type="submit" v-on:click="cancel()">
          キャンセル
        </button>
      </div>
    </div>
  </div>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
const tus = require("tus-js-client");
const Vimeo = require("vimeo").Vimeo;
var VIMEO_CLIENT_ID = process.env.VUE_APP_VIMEO_CLIENT_ID;
var VIMEO_CLIENT_SECRET = process.env.VUE_APP_VIMEO_CLIENT_SECRET;
var VIMEO_ACCESS_TOKEN = process.env.VUE_APP_VIMEO_ACCESS_TOKEN;

/* 事前に用意したID/秘密鍵/アクセストークンを設定してください */
const client = new Vimeo(
  VIMEO_CLIENT_ID, // ID
  VIMEO_CLIENT_SECRET, // 秘密鍵
  VIMEO_ACCESS_TOKEN // アクセストークン
);

export default {
  name: "ProjectCreate",
  data: function () {
    return {
      project_id: "",
      project_name: "",
      contract_start_day: "",
      contract_finished_day: "",
      url: "",
    };
  },
  methods: {
    /**
     * プロジェクトの作成
     */
    reserve: async function () {
      var router = this.$router;
      var serviceLogo = "1";
      var userdNumber = "0";
      var userdDataContents = "0";
      if (
        //フォームが全て記入済みなら
        this.project_name != "" &&
        this.contract_start_day != "" &&
        this.contract_finished_day != "" &&
        this.url != ""
      ) {
        //ローディング表示
        this.waitLoading();

        //アラートを全て非表示にする
        document.getElementById("project-name-alert").style.display = "none";
        document.getElementById("contract-period-alert").style.display = "none";
        document.getElementById("url-alert").style.display = "none";

        //日付のフォーマットを変更する
        var changeFormatContractStartDay = this.contract_start_day.replace(
          /\-/g,
          "/"
        );
        var changeFormatContractFinishedDay =
          this.contract_finished_day.replace(/\-/g, "/");

        //プロジェクトIDの生成
        this.getUniqueStr();

        //全公開中コンテンツを格納している変数に新しく作成したプロジェクトの公開中コンテンツを追加する
        this.createPublishingContents();

        this.createFolder(
          changeFormatContractStartDay,
          changeFormatContractFinishedDay,
          userdNumber,
          serviceLogo,
          userdDataContents,
          router
        );
      } else {
        //一つでも未記入があれば
        if (this.project_name == "") {
          document.getElementById("project-name-alert").style.display = "block";
        } else {
          document.getElementById("project-name-alert").style.display = "none";
        }
        if (this.contract_start_day == "" || this.contract_finished_day == "") {
          document.getElementById("contract-period-alert").style.display =
            "block";
        } else {
          document.getElementById("contract-period-alert").style.display =
            "none";
        }
        if (this.url == "") {
          document.getElementById("url-alert").style.display = "block";
        } else {
          document.getElementById("url-alert").style.display = "none";
        }
      }
    },
    /**
     * firebase storageにプロジェクトの情報を追加する
     */
    addProjectFirebaseStorage: function (
      changeFormatContractStartDay,
      changeFormatContractFinishedDay,
      userdNumber,
      serviceLogo,
      userdDataContents,
      router,
      vimeoFolder
    ) {
      var instance = this;

      //全プロジェクトのリストを取得しておく。ここに新しく作成するプロジェクトを格納し、DBに保存する
      var allProject = this.$store.state.projectInfo[0];

      //このパスにプロジェクトを保存する
      const ref = firebase
        .storage()
        .ref()
        .child(
          "/project/" +
            this.$store.state.clientId +
            "/" +
            this.$store.state.seacretClientId +
            "/project.json"
        );

      //新たに追加するプロジェクト情報
      const addProjectInfo = {
        project_id: this.project_id,
        project_name: this.project_name,
        vimeo_folder_pass: vimeoFolder,
        contract_start_day: changeFormatContractStartDay,
        contract_finished_day: changeFormatContractFinishedDay,
        used_number: userdNumber,
        service_logo: serviceLogo,
        used_data_contents: userdDataContents,
        site_url: this.url,
        post_form:
          //"https://influencer-form-2ca1f.web.app"+
          "form.short-video.jp" + //本番用コード
          "?hgisr=" +
          this.$store.state.clientId +
          "&shrkghkkgj=" +
          this.project_id,
        embedded_url:
          // "https://iframe-93448.web.app" + //テスト用コード
          "https://short-video.jp" + //本番用コード
          "?client_id=" +
          this.$store.state.clientId +
          "&project_id=" +
          this.project_id,
      };

      allProject.push(addProjectInfo);

      //jsonフォーマットに変換
      var jsonFormatAllProject = JSON.stringify(allProject);

      const blob = new Blob([jsonFormatAllProject], {
        type: "application/json",
      });
      ref.put(blob).then(function (result) {
        instance.createDBFolder(vimeoFolder);
      });
    },
    /**
     * プロジェクトIDの生成
     * @param {myStrong} 実際は何も入らない
     */
    getUniqueStr: function (myStrong) {
      //クライアントIDを生成する
      var strong = 1000;
      if (myStrong) strong = myStrong;
      this.project_id =
        new Date().getTime().toString(16) +
        Math.floor(strong * Math.random()).toString(16);
    },
    /**
     * キャンセルボタン押下時の処理
     */
    cancel: function () {
      this.$router.push("projectManagiment");
    },
    /**
     * vimeoに動画登録
     */
    createFolder: async function (
      changeFormatContractStartDay,
      changeFormatContractFinishedDay,
      userdNumber,
      serviceLogo,
      userdDataContents,
      router
    ) {
      var store = this.$store;
      var thisObject = this;
      await client.request(
        {
          method: "POST",
          path: "/me/projects/",
          query: {
            name: store.state.clientId + "/" + thisObject.project_id,
          },
        },
        await function (error, body, status_code, headers) {
          //firebaseStorageにプロジェクトを追加する
          thisObject.addProjectFirebaseStorage(
            changeFormatContractStartDay,
            changeFormatContractFinishedDay,
            userdNumber,
            serviceLogo,
            userdDataContents,
            router,
            body.uri
          );
        }
      );
    },
    /**
     * DBフォルダーにプロジェクト情報を作成
     */
    createDBFolder: function (vimeoFolder) {
      //このパスにjsonファイルを格納する
      const ref = firebase
        .storage()
        .ref()
        .child(
          "/db/" + this.$store.state.clientId + "/" + this.project_id + ".json"
        );
      var instance = this;
      var userdDataContents = "0";

      console.log("createDBFolder");
      console.log(vimeoFolder);

      //このjsonはインフルエンサー投稿フォームで読み取られる
      var projectInfoDB = {
        projectId: this.project_id,
        project_name: this.project_name,
        vimeo_folder_pass: vimeoFolder,
        used_data_contents: userdDataContents,
        used_number: 0,
      };

      //jsonに変換
      const jsonString = JSON.stringify(projectInfoDB);
      const blob = new Blob([jsonString], { type: "application/json" });
      ref.put(blob).then(function (result) {
        instance.createServiceLogoFolder();
      });
    },
    /**
     * サービスロゴの設定値が記載されたjsonを格納しているフォルダの作成
     */
    createServiceLogoFolder: function () {
      var instance = this;
      var router = this.$router;
      //このパスにjsonファイルを格納する
      const ref = firebase
        .storage()
        .ref()
        .child(
          "/serviceLogo/" +
            this.$store.state.clientId +
            "/" +
            this.project_id +
            "/" +
            "serviceLogo.json"
        );

      var serviceLogoDisplay = {
        service_logo: 1,
        site_url: this.url,
      };
      //jsonに変換
      const jsonServiceLogoDisplay = JSON.stringify(serviceLogoDisplay);
      const blob = new Blob([jsonServiceLogoDisplay], {
        type: "application/json",
      });
      ref.put(blob).then(function (result) {
        //ローディング非表示
        instance.downloaded();
        router.push("projectManagiment");
      });
    },
    createPublishingContents: function () {
      console.log("publishingConte");
      this.$store.commit("setAllPublishingContents", {
        project_id: this.project_id,
        publishingNum: "0",
      });
    },
    /**
     * ローディング
     */
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    /**
     * データ取得後
     */
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap");

.project-name {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.project-period {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.site-url {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: bold;
}
#project_name {
  border: 2px solid rgb(170, 165, 165); /* 枠線 */
  background-color: rgb(255, 255, 255); /* 背景色 */
}

#contract_start_day {
  border: 2px solid rgb(170, 165, 165); /* 枠線 */
  background-color: rgb(255, 255, 255); /* 背景色 */
}

#contract_finished_day {
  border: 2px solid rgb(170, 165, 165); /* 枠線 */
  background-color: rgb(255, 255, 255); /* 背景色 */
}

#site-url {
  border: 2px solid rgb(170, 165, 165); /* 枠線 */
  background-color: rgb(255, 255, 255); /* 背景色 */
}

.container {
  margin-top: 5%;
  margin-left: 10%;
}

.subject-project-register {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 32px;
  margin-left: 0%;
}

.client_name {
  margin-top: 3%;
}

.client-web-url {
  margin-top: 27px;
}

.event_priod {
  margin-top: 27px;
}

.radio_button {
  transform: scale(1.5);
}

label {
  display: block;
}

span {
  margin-left: 7%;
}

.reserve-button {
  margin-top: 60px;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  outline: 0;
  background: linear-gradient(#20b2aa, 5%, #5f9ea0);
  width: 100px;
  height: 40px;
  border: 0;
  color: #ffffff;
}

@media (min-width: 0px) {
  .cancel-button {
    margin-top: 60px;
    margin-left: 290%;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    outline: 0;
    background: linear-gradient(#f5f5f5, 1%, #a9a9a9);
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}
@media (min-width: 700px) {
  .cancel-button {
    margin-top: 60px;
    margin-left: 290%;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    outline: 0;
    background: linear-gradient(#f5f5f5, 1%, #a9a9a9);
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}
@media (min-width: 768px) {
  .cancel-button {
    margin-top: 60px;
    margin-left: 130%;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    outline: 0;
    background: linear-gradient(#f5f5f5, 1%, #a9a9a9);
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}
@media (min-width: 960px) {
  .cancel-button {
    margin-top: 60px;
    margin-left: 70%;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    outline: 0;
    background: linear-gradient(#f5f5f5, 1%, #a9a9a9);
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}
@media (min-width: 1200px) {
  .cancel-button {
    margin-top: 60px;
    margin-left: 70%;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    outline: 0;
    background: linear-gradient(#f5f5f5, 1%, #a9a9a9);
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}
@media (min-width: 1264px) {
  .cancel-button {
    margin-top: 60px;
    margin-left: 20%;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    outline: 0;
    background: linear-gradient(#f5f5f5, 1%, #a9a9a9);
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}

@media (min-width: 576px) {
  .contract_start_day {
    margin-right: 0%;
  }
  .contract_finished_day {
    margin-left: -1.5%;
  }
  .wavy_line {
    width: 10px;
    margin-top: 0.1%;
    margin-left: 28%;
  }
}
@media (min-width: 768px) {
  .contract_start_day {
    margin-right: 0%;
  }
  .contract_finished_day {
    margin-left: -2%;
  }
  .wavy_line {
    width: 10px;
    margin-top: 0.1%;
    margin-left: 18%;
  }
}
@media (min-width: 960px) {
  .contract_start_day {
    margin-right: 0%;
  }
  .contract_finished_day {
    margin-left: -3.3%;
  }
  .wavy_line {
    width: 10px;
    margin-top: 0.1%;
    margin-left: 12.5%;
  }
}
@media (min-width: 992px) {
  .contract_start_day {
    margin-right: 0%;
  }
  .contract_finished_day {
    margin-left: -3.3%;
  }
  .wavy_line {
    width: 10px;
    margin-top: 0.1%;
    margin-left: 12.5%;
  }
}
@media (min-width: 1200px) {
  .contract_start_day {
    margin-right: 0%;
  }
  .contract_finished_day {
    margin-left: -3.5%;
  }
  .wavy_line {
    width: 10px;
    margin-top: 0.1%;
    margin-left: 12.5%;
  }
}
@media (min-width: 1264px) {
  .contract_start_day {
    margin-right: 0%;
  }
  .contract_finished_day {
    margin-left: -4.5%;
  }
  .wavy_line {
    width: 10px;
    margin-top: 0.1%;
    margin-left: 7.5%;
  }
}

ul.topnav {
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: #1b2538;
  height: 60px;
}
ul.topnav li {
  float: left;
}
ul.topnav li {
  display: block;
  padding: 14px 16px;
  text-align: center;
  text-decoration: none;
  color: white;
}

ul.topnav li.right {
  float: right;
}

.used-content {
  position: relative;
  background: linear-gradient(#ff8c00, 25%, #ff5f17);
  height: 20px;
  top: 12px;
}

p {
  position: relative;
  top: -12px;
}

.value {
  position: relative;
  height: 20px;
  top: 12px;
}

.navigation {
  margin-top: 0.3%;
  margin-left: 9%;
}

.data {
  float: right;
}

.client-name {
  font-size: 20px;
}

#project-name-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

#contract-period-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

#url-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}
</style>