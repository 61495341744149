<template>
  <div class="container">
    <div class="row">
      <div class="subject-rewrite col-12">プロジェクト編集</div>
    </div>
    <div class="row client_name">
      <div class="col-2 project-name">プロジェクト名</div>
      <div class="col-1">
        <input
          style="width: 403px; height: 32px"
          type="text"
          id="project-name"
          name="client_name"
          :value="
            this.$store.state.projectInfo[0][this.$store.state.selectedProject]
              .project_name
          "
        />
      </div>
    </div>
    <p id="project-name-alert">※プロジェクト名を入力してください</p>
    <div class="row event_priod">
      <div class="col-2 project-period">プロジェクト期間</div>
      <div class="col-1 contract_start_day">
        <input
          style="width: 170px"
          type="date"
          id="contract_start_day"
          name="contract_start_day"
          :value="
            this.$store.state.projectInfo[0][
              this.$store.state.selectedProject
            ].contract_start_day
              .toString()
              .replace(/\//g, '-')
          "
        />
      </div>
      <div class="col-1 wavy_line"><span>〜</span></div>
      <div class="col-1 contract_finished_day">
        <input
          style="width: 170px"
          type="date"
          id="contract_finished_day"
          name="contract_finished_day"
          :value="
            this.$store.state.projectInfo[0][
              this.$store.state.selectedProject
            ].contract_finished_day
              .toString()
              .replace(/\//g, '-')
          "
        />
      </div>
    </div>
    <p id="contract-period-alert">※期間を選択してください</p>
    <div class="row serrvice-logo">
      <div class="col-2 servie-logo">サービス名ロゴ</div>
      <label class="display-radio-button">
        <input type="radio" class="radio_button" name="logo" value="1" />表示
      </label>
      <label class="display-radio-button">
        <input type="radio" class="radio_button" name="logo" value="0" /><span
          >非表示</span
        >
      </label>
    </div>
    <div class="row project-web-url-rewrite">
      <div class="col-2 site-url">掲載サイトURL</div>
      <div class="col-1">
        <input
          style="width: 403px; height: 100px vertical-align: top;"
          type="text"
          id="site-url"
          name="url"
          :value="
            this.$store.state.projectInfo[0][this.$store.state.selectedProject]
              .site_url
          "
        />
      </div>
    </div>
    <p id="url-alert">※URLを記入してください</p>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-1">
        <button class="reserve-button" type="submit" v-on:click="reserve()">
          保存
        </button>
      </div>
      <div class="col-1">
        <button class="cancel-button" type="submit" v-on:click="cancel()">
          キャンセル
        </button>
      </div>
    </div>
  </div>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
export default {
  name: "ProjectRewriteComponent",
  mounted() {
    this.selectedLogo();
  },
  data: function () {
    return {
      project_id:
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
          .project_id,
      used_number:
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
          .used_number,
      used_data_contents:
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
          .used_data_contents,
      post_form:
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
          .post_form,
      embedded_url:
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
          .embedded_url,
      vimeo_folder_pass:
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
          .vimeo_folder_pass,
      service_logo: "",
    };
  },
  methods: {
    /////////////////////////////////////
    ////////////////////////////
    ////////説明
    ///////サービス名ロゴの表示/非表示の判別をし、反映させる
    /////
    /////引数：なし
    ///////////////////
    //////////////////////////////////////////////////
    selectedLogo: async function () {
      var logoDisplay =
        this.$store.state.projectInfo[0][this.$store.state.selectedProject]
          .service_logo; //0か1が入る

      let serviceLogoElements = document.getElementsByName("logo"); //ラジオボタンの情報が入る
      if (logoDisplay == "1") {
        serviceLogoElements.item(0).checked = true;
      } else {
        serviceLogoElements.item(1).checked = true;
      }
    },
    /////////////////////////////////////
    ////////////////////////////
    ////////説明
    ///////サービス名ロゴの表示/非表示を保存する
    /////
    /////引数：なし
    ///////////////////
    //////////////////////////////////////////////////
    reserveServiceLogo: function () {
      let serviceLogoElements = document.getElementsByName("logo"); //ラジオボタンの情報が入る
      if (serviceLogoElements.item(0).checked == true) {
        this.service_logo = 1;
      } else {
        this.service_logo = 0;
      }
    },
    /////////////////////////////////////
    ////////////////////////////
    ////////説明
    ///////変更を保存する処理
    /////
    /////引数：なし
    ///////////////////
    //////////////////////////////////////////////////
    reserve: function () {
      if (
        //フォームが全て記入済みなら
        document.getElementById("project-name").value != "" &&
        document.getElementById("contract_start_day").value != "" &&
        document.getElementById("contract_finished_day").value != "" &&
        document.getElementById("site-url").value != ""
      ) {
        //ローディング表示
        this.waitLoading();

        //アラートを非表示にする
        document.getElementById("project-name-alert").style.display = "none";
        document.getElementById("contract-period-alert").style.display = "none";
        document.getElementById("url-alert").style.display = "none";

        //サービス名ロゴの表示/非表示をデータに保存する
        this.reserveServiceLogo();

        //プロジェクトの情報を編集する
        this.rewriteProjectInfo();
      } else {
        //一つでも未記入があれば
        if (document.getElementById("project-name").value == "") {
          document.getElementById("project-name-alert").style.display = "block";
        } else {
          document.getElementById("project-name-alert").style.display = "none";
        }
        if (
          document.getElementById("contract_start_day").value == "" ||
          document.getElementById("contract_finished_day").value == ""
        ) {
          document.getElementById("contract-period-alert").style.display =
            "block";
        } else {
          document.getElementById("contract-period-alert").style.display =
            "none";
        }
        if (document.getElementById("site-url").value == "") {
          document.getElementById("url-alert").style.display = "block";
        } else {
          document.getElementById("url-alert").style.display = "none";
        }
      }
    },
    /////////////////////////////////////
    ////////////////////////////
    ////////説明
    ///////プロジェクトの編集をfirebasestorageに保存する
    /////
    /////引数：なし
    ///////////////////
    //////////////////////////////////////////////////
    rewriteProjectInfo: function () {
      var router = this.$router;
      var instance = this;

      //jsonに保存する値を取得する
      var projectName = document.getElementById("project-name").value;
      var projectStartDay = document.getElementById("contract_start_day").value;
      var projectFinishedDay = document.getElementById(
        "contract_finished_day"
      ).value;
      var siteUrl = document.getElementById("site-url").value;
      var changeFormatContractStartDay = projectStartDay.replace(/\-/g, "/");
      var changeFormatContractFinishedDay = projectFinishedDay.replace(
        /\-/g,
        "/"
      );

      //選択中のプロジェクトの情報を取得する
      var selectedProject = this.$store.state.projectInfo[0];
      selectedProject[this.$store.state.selectedProject].project_name =
        projectName;
      selectedProject[this.$store.state.selectedProject].contract_start_day =
        changeFormatContractStartDay;
      selectedProject[this.$store.state.selectedProject].contract_finished_day =
        changeFormatContractFinishedDay;
      selectedProject[this.$store.state.selectedProject].site_url = siteUrl;
      selectedProject[this.$store.state.selectedProject].service_logo =
        this.service_logo;

      //jsonフォーマットに変換する
      var jsonFormat = JSON.stringify(selectedProject);

      //このパスに保存する
      const ref = firebase
        .storage()
        .ref()
        .child(
          "/project/" +
            this.$store.state.clientId +
            "/" +
            this.$store.state.seacretClientId +
            "/project.json"
        );

      const blob = new Blob([jsonFormat], { type: "application/json" });
      ref.put(blob).then(function (result) {
        var projectId =
          selectedProject[instance.$store.state.selectedProject].project_id;
        var projectName =
          selectedProject[instance.$store.state.selectedProject].project_name;
        var vimeoFolder =
          selectedProject[instance.$store.state.selectedProject]
            .vimeo_folder_pass;
        var usedContents =
          selectedProject[instance.$store.state.selectedProject]
            .used_data_contents;

        var usedNumber =
          selectedProject[instance.$store.state.selectedProject].used_number;
        instance.rewriteDBFolder(
          projectId,
          projectName,
          vimeoFolder,
          usedContents,
          usedNumber,
          siteUrl
        );
      });
    },
    /////////////////////////////////////
    ////////////////////////////
    ////////説明
    ///////前画面に戻る
    /////
    /////引数：なし
    ///////////////////
    //////////////////////////////////////////////////
    cancel: async function () {
      this.$router.push("projectManagiment");
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得している間の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得した後の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //DBフォルダーを編集する
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    rewriteDBFolder: function (
      projectId,
      projectName,
      vimeoFolder,
      userdDataContents,
      usedNumber,
      siteUrl
    ) {
      var instance = this;
      //このパスに保存する
      const ref = firebase
        .storage()
        .ref()
        .child(
          "/db/" +
            this.$store.state.clientId +
            "/" +
            this.$store.state.projectInfo[0][this.$store.state.selectedProject]
              .project_id +
            ".json"
        );

      //このjsonはインフルエンサー投稿フォームで読み取られる
      var projectInfoDB = {
        projectId: projectId,
        project_name: projectName,
        vimeo_folder_pass: vimeoFolder,
        used_data_contents: userdDataContents,
        used_number: usedNumber,
      };
      //jsonに変換
      const jsonString = JSON.stringify(projectInfoDB);

      const blob = new Blob([jsonString], { type: "application/json" });
      ref.put(blob).then(function (result) {
        instance.rewriteServiceLogoFolder(siteUrl);
      });
    },
    /////////////////////////////////////////
    ////////////////////////////
    /////説明
    ////サービスロゴの表示、非表示が格納されたjsonを格納しているフォルダを作成
    ////
    ////引数：なし
    /////////////////////////
    //////////////////////////////////////////////////
    rewriteServiceLogoFolder: function (siteUrl) {
      var instance = this;
      var router = this.$router;
      //このパスにjsonファイルを格納する
      const ref = firebase
        .storage()
        .ref()
        .child(
          "/serviceLogo/" +
            this.$store.state.clientId +
            "/" +
            this.project_id +
            "/" +
            "serviceLogo.json"
        );
      //このjsonはインフルエンサー投稿フォームで読み取られる
      var serviceLogoDisplay = {
        service_logo: this.service_logo,
        site_url: siteUrl,
      };
      //jsonに変換
      const jsonServiceLogoDisplay = JSON.stringify(serviceLogoDisplay);
      const blob = new Blob([jsonServiceLogoDisplay], {
        type: "application/json",
      });
      ref.put(blob).then(function (result) {
        //ローディング非表示
        instance.downloaded();
        router.push("projectManagiment");
      });
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap");
.client_rewrite_body {
  background-color: #f5f5f5;
}

.project-name {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.project-period {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.servie-logo {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.site-url {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

#project-name-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}
#contract-period-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

#url-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

#project-name {
  border: 2px solid rgb(170, 165, 165); /* 枠線 */
  background-color: rgb(255, 255, 255); /* 背景色 */
}

#contract_start_day {
  border: 2px solid rgb(170, 165, 165); /* 枠線 */
  background-color: rgb(255, 255, 255); /* 背景色 */
}

#contract_finished_day {
  border: 2px solid rgb(170, 165, 165); /* 枠線 */
  background-color: rgb(255, 255, 255); /* 背景色 */
}

#site-url {
  border: 2px solid rgb(170, 165, 165); /* 枠線 */
  background-color: rgb(255, 255, 255); /* 背景色 */
}

.container {
  margin-top: 2%;
  margin-left: 10%;
}

.subject-rewrite {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 32px;
  margin-left: 0%;
}

.client_name {
  margin-top: 3%;
}

.project-web-url-rewrite {
  margin-top: 27px;
}

.client_name {
  margin-top: 3%;
}

.serrvice-logo {
  margin-top: 27px;
}

.event_priod {
  margin-top: 27px;
}

.radio_button {
  transform: scale(2);
  margin-right: 15%;
}

label {
  display: block;
}

span {
  margin-left: 7%;
}

.display-radio-button {
  display: inline-block;
  margin-left: 2%;
  margin-top: 15px;
  width: 9%;
}

@media (max-width: 991px) {
  .display-radio-button {
    display: inline-block;
    margin-left: 2%;
    width: 15%;
  }
}

.reserve-button {
  margin-top: 60px;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  outline: 0;
  background: linear-gradient(#20b2aa, 5%, #5f9ea0);
  width: 100px;
  height: 40px;
  border: 0;
  color: #ffffff;
}
@media (min-width: 0px) {
  .cancel-button {
    margin-top: 60px;
    margin-left: 290%;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    outline: 0;
    background: linear-gradient(#f5f5f5, 1%, #a9a9a9);
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}
@media (min-width: 700px) {
  .cancel-button {
    margin-top: 60px;
    margin-left: 290%;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    outline: 0;
    background: linear-gradient(#f5f5f5, 1%, #a9a9a9);
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}
@media (min-width: 768px) {
  .cancel-button {
    margin-top: 60px;
    margin-left: 130%;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    outline: 0;
    background: linear-gradient(#f5f5f5, 1%, #a9a9a9);
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}
@media (min-width: 960px) {
  .cancel-button {
    margin-top: 60px;
    margin-left: 70%;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    outline: 0;
    background: linear-gradient(#f5f5f5, 1%, #a9a9a9);
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}
@media (min-width: 1200px) {
  .cancel-button {
    margin-top: 60px;
    margin-left: 70%;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    outline: 0;
    background: linear-gradient(#f5f5f5, 1%, #a9a9a9);
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}
@media (min-width: 1264px) {
  .cancel-button {
    margin-top: 60px;
    margin-left: 20%;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    outline: 0;
    background: linear-gradient(#f5f5f5, 1%, #a9a9a9);
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
}

@media (min-width: 576px) {
  .contract_start_day {
    margin-right: 0%;
  }
  .contract_finished_day {
    margin-left: -0.5%;
  }
  .wavy_line {
    width: 10px;
    margin-top: 0.1%;
    margin-left: 27%;
  }
}
@media (min-width: 768px) {
  .contract_start_day {
    margin-right: 0%;
  }
  .contract_finished_day {
    margin-left: -2.5%;
  }
  .wavy_line {
    width: 10px;
    margin-top: 0.1%;
    margin-left: 18%;
  }
}
@media (min-width: 960px) {
  .contract_start_day {
    margin-right: 0%;
  }
  .contract_finished_day {
    margin-left: -3.5%;
  }
  .wavy_line {
    width: 10px;
    margin-top: 0.1%;
    margin-left: 12.5%;
  }
}
@media (min-width: 992px) {
  .contract_start_day {
    margin-right: 0%;
  }
  .contract_finished_day {
    margin-left: -3.5%;
  }
  .wavy_line {
    width: 10px;
    margin-top: 0.1%;
    margin-left: 12.5%;
  }
}
@media (min-width: 1200px) {
  .contract_start_day {
    margin-right: 0%;
  }
  .contract_finished_day {
    margin-left: -3.5%;
  }
  .wavy_line {
    width: 10px;
    margin-top: 0.1%;
    margin-left: 12.5%;
  }
}
@media (min-width: 1264px) {
  .contract_start_day {
    margin-right: 0%;
  }
  .contract_finished_day {
    margin-left: -4.5%;
  }
  .wavy_line {
    width: 10px;
    margin-top: 0.1%;
    margin-left: 7.5%;
  }
}

ul.topnav {
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: #1b2538;
  height: 60px;
}
ul.topnav li {
  float: left;
}
ul.topnav li {
  display: block;
  padding: 14px 16px;
  text-align: center;
  text-decoration: none;
  color: white;
}

ul.topnav li.right {
  float: right;
}

.used-content {
  position: relative;
  background: linear-gradient(#ff8c00, 25%, #ff5f17);
  height: 20px;
  top: 12px;
}

p {
  position: relative;
  top: -12px;
}

.value {
  position: relative;
  height: 20px;
  top: 12px;
}

.navigation {
  margin-top: 0.3%;
  margin-left: 9%;
}

.data {
  float: right;
}

.client-name {
  font-size: 20px;
}
</style>