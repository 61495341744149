<template>
  <div class="container">
    <div class="subject">管理者一覧</div>
    <div class="button-list">
      <button
        class="project_list_button"
        v-on:click="changeProjectListScreen()"
      >
        プロジェクト一覧
      </button>
      <button class="new-create-button" v-on:click="changeCreateAdminScreen()">
        新規作成+
      </button>
    </div>
    <div>
      <table class="table">
        <thead>
          <tr class="topic">
            <th width="20%">管理者名</th>
            <th width="20%">メールアドレス</th>
            <th width="10%">パスワード</th>
            <th width="0%"></th>
            <th width="0%"></th>
            <th colspan="3"></th>
          </tr>
        </thead>
        <tbody v-for="(admin, index) in admins" :key="index">
          <tr height="50">
            <td>{{ admin.admin_name }}</td>
            <td class="id-area">{{ admin.mail_address }}</td>
            <td class="pass-area">{{ admin.pass }}</td>
            <td>
              <button
                class="copy-button"
                type="button"
                v-on:click="copy(index)"
              >
                IDとパスワードをコピー
              </button>
            </td>
            <td width="20">
              <button
                class="delete-button"
                type="submit"
                v-on:click="deleteModalShow(index)"
                v-if="admin.authority == '0' || admin.authority == ''"
              >
                削除
              </button>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="deleteModal" class="modal">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-content">
            <p class="reserve-text">削除後復元出来ません。</p>
            <p class="reserve-text">削除しますか？</p>
            <div class="btn-modal">
              <div>
                <button
                  class="modal-cancel-button modalClose"
                  type="submit"
                  v-on:click="deleteAdmin()"
                >
                  削除
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
export default {
  name: "AdminListHomeComponent",
  data: function () {
    return {};
  },
  computed: {
    admins: {
      get() {
        return this.$store.state.admins[0];
      },
      set() {
        return this.$store.state.admins[0];
      },
    },
  },
  methods: {
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////削除モーダルを表示する
    /////////
    ////////引数：index:選択した管理者のインデックス
    /////////////////////////////////////////////////
    deleteModalShow: function (index) {
      this.$store.commit("selectedAdminSetter", index);

      //削除モーダルを表示させる
      document.getElementById("deleteModal").style.display = "block";
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////プロジェクト一覧に戻る
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    changeProjectListScreen: function () {
      this.$router.push("projectManagiment");
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////削除モーダルを非表示にする
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deleteAdmin: async function () {
      //ローディング表示
      this.waitLoading();

      //削除モーダルを非表示にする
      document.getElementById("deleteModal").style.display = "none";

      //管理者を削除する
      await this.deleteAdminInfo();

      //ローディング非表示
      this.downloaded();
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////sotrageとauthenticationから管理者を削除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deleteAdminInfo: async function () {
      //authenticationからアカウントを削除する
      await this.deleteAuthentication();

      //firebase storageからユーザーを削除
      await this.deleteFirebaseStorage();
    },
    ///////////////////////////////////test_01@gmail.com","pass":"4Uplx9VC
    /////////////
    //////////説明
    //////////authenticationから管理者を削除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deleteAuthentication: async function () {
      console.log("-----deleteAuthentication--------")
      //削除するユーザーが格納される
      var deleteUser;
      await firebase.auth().signOut();
      await firebase
        .auth()
        .signInWithEmailAndPassword(
          this.$store.state.admins[0][this.$store.state.selectedAdmin]
            .mail_address,
          this.$store.state.admins[0][this.$store.state.selectedAdmin].pass
        )
        .then(
          (user) => {
            deleteUser = firebase.auth().currentUser;
          },
          (error) => {
            alert("削除に失敗しました。");
          }
        );
      await deleteUser.delete().then(() => {
        console.log("削除しました");
      });
      await firebase
        .auth()
        .signInWithEmailAndPassword(
          this.$store.state.loginEmail,
          this.$store.state.loginPassword
        )
        .then(() => {
          console.log("サインインしました");
        });
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////storageに保存してある管理者のjsonから該当するユーザーを削除する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    deleteFirebaseStorage: async function () {
      console.log("-----deleteFirebaseStorage--------")

      var store = this.$store;
      //すべての管理者を取得する。この中から削除するユーザーを消す
      var managerList = this.$store.state.admins;

      //削除するユーザーのインデックス番号
      var deleteUserIndex = this.$store.state.selectedAdmin;

      //一度にユーザーを削除する数
      var deleteQuantity = 1;

      //jsonから該当ユーザーを削除する
      managerList[0].splice(deleteUserIndex, deleteQuantity);

      //マスター管理者のインデックス番号
      var masterAccountIndex = 0;
    
      //storageに送る用の配列を作成する
      var reManagerList=managerList[0].map(function(manager){
        return manager;
      })

       //マスター管理者をjsonから削除する。マスター管理者はこのjsonでは管理しないため
      reManagerList.splice(masterAccountIndex, deleteQuantity);

      /////////////////jsonをfirebasestorageに登録しなおす
      //保存先パス
      const ref = firebase
        .storage()
        .ref()
        .child("/account/" + this.$store.state.clientId + "/managerList.json");

      //jsonフォーマットに変換
      var jsonFormat = JSON.stringify(reManagerList);

      const blob = new Blob([jsonFormat], { type: "application/json" });
      ref.put(blob).then(() => {
      });
    },

    ///////////////////////////////////
    /////////////
    //////////説明
    //////////新規作成画面に遷移する
    /////////
    ////////引数：なし
    /////////////////////////////////////////////////
    changeCreateAdminScreen: function () {
      this.$router.push("managimentRegister");
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////IDとパスワードをコピーする
    /////////
    ////////引数：index:コピーボタンを押した行のインデックスを取得
    /////////////////////////////////////////////////
    copy: function (index) {
      var clientInfo = this.$store.state.clientInfo[0];
      var clientId = clientInfo.client_id;
      var seacretId = clientInfo.seacretId;
      var idarea = document.getElementsByClassName("id-area")[index].innerHTML;
      var passarea =
        document.getElementsByClassName("pass-area")[index].innerHTML;
      var text = document.createElement("textarea");
      //text.value = idarea + passarea;
      text.value =
        "管理画面へのログイン情報です。\nログインURL:https://client.short-video.jp/?rrdwa=" +
        clientId +
        "&sec=" +
        seacretId +
        "\nID:" +
        idarea +
        "\nPASS:" +
        passarea;
      document.body.appendChild(text);
      text.select();
      document.execCommand("copy");
      document.body.removeChild(text);
      alert("コピーしました");
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得している間の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得した後の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap");
body {
  margin: 0%;
}

.subject {
  font-size: 32px;
  font-family: "Noto Sans JP", sans-serif;
  margin-top: 5%;
}

.topic {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  background-color: #f5f5f5;
}

td {
  vertical-align: middle;
  font-family: "Noto Sans JP", sans-serif;
}

.new-create-button {
  font-size: 13px;
  margin-right: 10px;
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: transparent linear-gradient(180deg, #e0803e 0%, #d66212 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  width: 88px;
  height: 28px;
  border: 0;
  color: #ffffff;
}

.project_list_button {
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  width: 120px;
  height: 28px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #df7e3b;
  opacity: 1;
  color: #df7e3b;
  margin-right: 8px;
  font-size: 13px;
}

.button-list {
  text-align: right;
  margin-right: 3%;
  margin-bottom: 1%;
}

.delete-button {
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  outline: 0;
  background: transparent linear-gradient(180deg, #b0b0b0 0%, #909090 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  width: 56px;
  height: 24px;
  border: 0;
  margin-left: 200px;
  color: #ffffff;
}

.rewrite-button {
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: linear-gradient(#ff8c00, 25%, #ff5f17);
  width: 70px;
  height: 27px;
  border: 0;
  color: #ffffff;
}

p {
  position: relative;
  top: -12px;
}

.value {
  position: relative;
  height: 20px;
  top: 12px;
}

.data {
  float: right;
}

.client-name {
  font-size: 20px;
}

.copy-button {
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background-color: #f5f5f5;
  width: 136px;
  height: 20px;
  border: 0;
  color: grey;
  font-size: 12px;
  box-shadow: 0 1px 2px gray;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgb(82, 80, 80);
}

.modal-content {
  background-color: rgba(255, 255, 255, 0.945);
  color: rgb(0, 0, 0);
  margin: 20% auto;
  width: 30%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  animation-name: modalopen;
  animation-duration: 1s;
}

.modal-reserve-button {
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: linear-gradient(#20b2aa, 5%, #5f9ea0);
  width: 80px;
  height: 30px;
  border: 0;
  color: #ffffff;
}

.modal-cancel-button {
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: transparent linear-gradient(180deg, #b1b1b1 0%, #8e8e8e 100%) 0%
    0% no-repeat padding-box;
  border: 0.5px solid #a5a5a5;
  opacity: 1;
  width: 80px;
  height: 30px;
  font-weight: bold;
  border: 0;
  color: #ffffff;
  margin-top: 3%;
}

.btn-modal {
  text-align: center;
  margin-top: 3%;
  margin-bottom: 5%;
}

.btn-modal > div {
  margin-left: 5%;
  margin-right: 5%;
  display: inline-block;
}
.reserve-text {
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
  font-size: 24px;
  margin-top: 5%;
}
</style>